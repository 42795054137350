import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Topping } from './topping.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Cache-Control': 'public'
  })
}


@Injectable()
export class ToppingService {
  toppingsUrl = '/toppings'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ToppingsService');
  }

  getToppings(): Observable<Topping[]> {
    return this.http.get<Topping[]>(API_URL + this.toppingsUrl, httpOptions)
      .pipe(
        catchError(this.handleError('getToppings', []))
      )
  }

  getTopping(toppingId: string): Observable<Topping[]> {
    return this.http.get<Topping[]>(API_URL + this.toppingsUrl + '/' + toppingId, httpOptions)
      .pipe(
        catchError(this.handleError('getTopping', []))
      )
  }

  getToppingsByProduct(productId: string): Observable<Topping[]> {
    return this.http.get<Topping[]>(API_URL + this.toppingsUrl + '/product/' + productId, httpOptions)
      .pipe(
        catchError(this.handleError('getToppingsByProduct', []))
      )
  }
}
