import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Point } from './point.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
}


@Injectable()
export class PointService {
  actionsUrl = '/points'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('PointService');
  }

  getPointsByCity(cityId: string): Observable<Point[]> {
    return this.http.get<Point[]>(API_URL + this.actionsUrl + '/city/' + cityId, httpOptions)
      .pipe(
        catchError(this.handleError('getPointsByCity', []))
      )
  }
}
