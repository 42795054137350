import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { CartService } from '../../../models/cart.service';
import { Page } from '../../../models/page.model'
import { City } from '../../../models/city.model'
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageService } from '../../../models/page.service';
import { CityService } from '../../../models/city.service';
import { GeolocationService } from '../../../models/geolocation.service';
import { AppStorage } from '@shared/for-storage/universal.inject';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment'
import { PointService } from '../../../models/point.service';
import { Point } from '../../../models/point.model'

import * as $ from 'jquery'
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../models/category.service';
declare var UIkit: any

@Component({
    selector: 'app-wrapper',
    templateUrl: './wrapper.component.html'
})
export class WrapperComponent implements OnInit {
    public pages: Page[]
    public cities: City[]
    public cityForm: FormGroup
    public isRootRoute: boolean
    public isMap: boolean
    public isCityBoxIn: boolean
    public isCityBoxOut: boolean
    public isTimeBoxIn: boolean
    public isDownloadAppBoxIn: boolean
    public isCookieBoxIn: boolean
    public totalCart: number = 0
    public city: string
    public subdomainCity: string
    public subdomainCityPhone: string
    public error: string
    public categories: Category[]
    public categoriesImages: any
    public apiUrl: string
    public points: Point[]

    constructor(private location: Location,
        private pointService: PointService,
        private cartService: CartService,
        private pageService: PageService,
        private geolocationService: GeolocationService,
        private cityService: CityService,
        @Inject(AppStorage) private appStorage: Storage,
        private categoryService: CategoryService,
        private router: Router,) {
        this.calculateCart()
        this.isRootRoute = true
        this.isMap = false
        this.isCityBoxIn = false
        this.isCityBoxOut = false
        this.isTimeBoxIn = false
        this.isDownloadAppBoxIn = false
        this.isCookieBoxIn = false
        this.city = this.appStorage.getItem('city')
        this.apiUrl = environment.apiUrl
    }

    ngOnInit() {
        $('*[class*="sushi"]').removeClass('active')

        $('#open-menu-modal').click(() => {
            $('body').toggleClass('view-menu-modal')
        })
        $('.container-bottom-menu a:not(#open-menu-modal)').click(() => {
            $('body').removeClass('view-menu-modal')
        })
        $('#menu-modal *').click(() => {
            $('body').removeClass('view-menu-modal')
        })

        this.getCategories()
        this.getPages()

        this.getCurrentDate()

        if (this.city === null || this.city === '') {
            this.appStorage.setItem('city', environment.defaultCity)
            // this.createCookie("city", environment.defaultCity);
            this.getGeolocation()
        }

        this.subdomainCity = this.getSubdomainName(window.location.host)
        this.subdomainCityPhone = this.getSubdomainPhone(window.location.host)
    }

    getSubdomainName(url: string) {
        const urlAll = url.split('.');

        if (urlAll.includes('murmansk')) {
            return "Мурманск"
        }
        if (urlAll.includes('kirovsk')) {
            return "Кировск"
        }
        if (urlAll.includes('severomorsk')) {
            return "Североморск"
        }
        if (urlAll.includes('apatity')) {
            return "Апатиты"
        }
        if (urlAll.includes('monchegorsk')) {
            return "Мончегорск"
        }
        if (urlAll.includes('kovdor')) {
            return "Ковдор"
        }
        if (urlAll.includes('olenegorsk')) {
            return "Оленегорск"
        }

        return "Мурманск"
    }

    getSubdomainPhone(url: string) {
        const urlAll = url.split('.');

        if (urlAll.includes('murmansk')) {
            return "8 (8152) 70-49-49"
        }
        if (urlAll.includes('kirovsk')) {
            return "8 (902) 131-55-55"
        }
        if (urlAll.includes('severomorsk')) {
            return "8 (8152) 60-49-49"
        }
        if (urlAll.includes('apatity')) {
            return "8 (902) 132-55-55"
        }
        if (urlAll.includes('monchegorsk')) {
            return "8 (8152) 20-44-22"
        }
        if (urlAll.includes('kovdor')) {
            return "8 (950) 899-33-66"
        }
        if (urlAll.includes('olenegorsk')) {
            return "8 (902) 130-55-55"
        }


        // default phone (murmansk)
        return "8 (8152) 70-49-49"
    }

    changeRoute() {
        $('body').removeClass('lazy-load-body')
        setTimeout(() => {
            $('body').addClass('lazy-load-body')
        }, 0)

        this.getPoints()
    }

    openCityBox() {
        this.getCities();
    }

    getPointsByCity(cityId: string) {
        this.pointService.getPointsByCity(cityId)
            .subscribe(data => {
                this.points = data['response'][0]
                this.city = this.appStorage.getItem('city') || environment.defaultCity;
            }, error => {
                this.error = error;
            })
    }

    getPoints() {
        const selectedCity = this.appStorage.getItem('city') || environment.defaultCity;
        if (!this.city) {
            this.city = selectedCity;
            this.appStorage.setItem('city', environment.defaultCity)
            // this.createCookie("city", environment.defaultCity);
            this.openCityBox();
        }

        this.cityService.getCityByName(selectedCity)
            .subscribe(data => {
                this.getPointsByCity(data['response']['_id']);
            }, error => {
                this.error = error;
            })
    }

    changeLocation() {

        // save current route first
        const currentRoute = this.router.url;

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]); // navigate to same route
        });
    }

    getCurrentDate() {
        // show timeBox
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const mins = currentDate.getMinutes();
        if (hours >= 23 || hours <= 10) {
            if (hours === 23 && mins >= 45) {
                this.isTimeBoxIn = true;
            } else if (hours === 10 && mins <= 15) {
                this.isTimeBoxIn = true;
            } else if (hours !== 23 && hours !== 10) {
                this.isTimeBoxIn = true;
            }
        }

        //show download app banner
        // if(!this.appStorage.getItem('downloadAppBanner')) {
        //     this.isDownloadAppBoxIn = true;
        // }

        //show cookie box
        if (!this.appStorage.getItem('cookieBanner')) {
            this.isCookieBoxIn = true;
        }
    }

    closeTimeBox() {
        this.isTimeBoxIn = false;
    }

    closeDownloadAppBox() {
        this.isDownloadAppBoxIn = false;
        this.createCookie("downloadAppBanner");
    }

    loadAppBoxClickImage() {
        this.router.navigate(['product/miksgrilakciya']);
        this.isDownloadAppBoxIn = false;
    }

    closeCookieBox() {
        this.isCookieBoxIn = false;
        this.createCookie("cookieBanner");
    }

    createCookie(cookieName: string, body: string = "true") {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 25)
        document.cookie = `${cookieName}=${body}; expires=${date.toUTCString()}; domain=.sushigurme.com`;
        // document.cookie = `${cookieName}=${body}`;
        // document.cookie = `${cookieName}=${body}; domain=.sushigurme.com`;
        // document.cookie = `${cookieName}=${body}; expires=${date.toUTCString()}`;
    }

    closeMenuModal() { }

    getCategories() {
        this.categoryService.getCategories()
            .subscribe(data => {
                this.categories = data['response']
                this.categoriesImages = []

                data['response'].map(category => {
                    let imagesString = ''
                    let count = 0
                    category.images.map(image => {
                        if (image.ext === 'jpeg') {
                            count++
                            if (count !== 4) {
                                imagesString += this.apiUrl + image.src + ' ' + image.width + 'w, '
                            } else {
                                imagesString += this.apiUrl + image.src + ' ' + image.width + 'w'
                            }
                        }
                    })
                    this.categoriesImages.push(imagesString)
                })
            }, error => {
                this.error = error
            })
    }

    notify(image: string, message: string) {
        UIkit.notification({
            message: `<div class="notif-content uk-flex uk-flex-middle">` +
                `<div class="notif-img uk-cover-container"><img src="${image}"></div>` +
                `<div class="notif-text uk-width-expand">${message}</div></div>`,
            status: 'primary',
            pos: 'top-center',
            timeout: 3000
        });
    }

    getPages() {
        this.pageService.getPages()
            .subscribe(data => {
                this.pages = data['response']
            }, error => {
                this.error = error;
            })
    }

    calculateCart() {
        this.cartService.calculateCart()
            .subscribe(data => {
                this.totalCart = data['response']
            }, error => {
                this.error = error;
            })
    }

    clearCart() {
        this.cartService.clearCart()
            .subscribe(data => {
                this.totalCart = 0
            }, error => {
                this.error = error;
            })
    }

    goBack() {
        this.location.back()
    }

    getState(outlet) { }

    // Get user geolocation
    getGeolocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.getCity(position.coords.latitude, position.coords.longitude, 'ru')
            }, error => {
                this.getCities()
            })
        } else {
            this.getCities()
            console.log('Geolocation is not supported by this browser.')
        }
    }

    // Set city by data form
    setCity() {
        this.appStorage.setItem('city', this.cityForm.get('city').value)
        // this.createCookie("city", this.cityForm.get('city').value);
        this.isCityBoxOut = true

        switch (this.cityForm.get('city').value) {
            case 'Мурманск': {
                window.location.assign('https://murmansk.sushigurme.com/home')
                break;
            }
            case 'Кировск': {

                window.location.assign('https://kirovsk.sushigurme.com/home')
                break;
            }
            case 'Североморск': {

                window.location.assign('https://severomorsk.sushigurme.com/home')
                break;
            }
            case 'Апатиты': {

                window.location.assign('https://apatity.sushigurme.com/home')
                break;
            }
            case 'Мончегорск': {

                window.location.assign('https://monchegorsk.sushigurme.com/home')
                break;
            }
            case 'Ковдор': {

                window.location.assign('https://kovdor.sushigurme.com/home')
                break;
            }
            case 'Оленегорск': {
                window.location.assign('https://olenegorsk.sushigurme.com/home')
                break;
            }
        }

        // this.getPoints();
        // this.changeLocation();
    }

    // Get city by google by user coordinates
    getCity(latitude: number, longitude: number, language: string) {
        this.geolocationService.getCity(latitude, longitude, language)
            .subscribe(data => {
                // Get city by name which google api return
                this.getCityByName(data['results'][0]['address_components'][4]['long_name'])
            }, error => {
                this.error = error;
            })
    }

    // Get cities list which delivery available
    getCities() {
        this.cityService.getCities()
            .subscribe(data => {
                this.cities = data['response']
                this.generateCityForm()
                this.isCityBoxIn = true
                this.isCityBoxOut = false;
            }, error => {
                this.error = error;
            })
    }

    // Get city by name
    getCityByName(name: string) {
        this.cityService.getCityByName(name)
            .subscribe(data => {
                if (data['response'] === null) {
                    this.getCities()
                } else {
                    this.appStorage.setItem('city', data['response'])
                    // this.createCookie("city", data['response']);
                }
            }, error => {
                this.error = error;
            })
    }

    // Generate city form
    generateCityForm() {
        this.cityForm = new FormGroup({
            city: new FormControl(this.cities[0]['name'], [
                Validators.required
            ])
        })
    }
}
