import { Component, Inject, Renderer2, OnInit } from '@angular/core';
import { AppStorage } from '@shared/for-storage/universal.inject';
import { MetaService } from '@ngx-meta/core';
import { Metrika } from 'ng-yandex-metrika';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { CartService } from './models/cart.service';
import { ComponentsService } from './models/components.service';
import { ProductService } from './models/product.service';
import { CategoryService } from './models/category.service';
import { ToppingService } from './models/topping.service';
import { PageService } from './models/page.service';
import { GeolocationService } from './models/geolocation.service';
import { CityService } from './models/city.service';
import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';
import { PointService } from './models/point.service';
const uuidv4 = require('uuid/v4')


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: [
    ToppingService,
    CategoryService,
    ProductService,
    CartService,
    ComponentsService,
    PageService,
    GeolocationService,
    CityService,
    PointService
  ]
})
export class AppComponent implements OnInit {
  public error: string

  constructor(
    private readonly meta: MetaService,
    @Inject(AppStorage) private appStorage: Storage,
    private metrika: Metrika,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
  ) {
    this.meta.setTag('og:title', 'Суши Гурмэ')

    if (!this.appStorage.getItem('sessionId')) {
      // document.cookie = `sessionId=${uuidv4()}; domain=.sushigurme.com`;
      this.appStorage.setItem('sessionId', uuidv4());
    }

// yandex metrica
    let prevPath = this.location.path();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      
      const newPath = this.location.path();
      this.metrika.hit(newPath, {
        referer: prevPath,
      });
      prevPath = newPath;
    });

    // const city = this.appStorage.getItem('city')
    // const locationArr = window.location.host.split('.')

    // switch (city) {
    //   case 'Мурманск': {
    //     if (!locationArr.includes('murmansk')) {
    //       window.location.assign('https://murmansk.sushigurme.com/home')
    //     }
    //     break;
    //   }
    //   case 'Кировск': {
    //     if (!locationArr.includes('kirovsk')) {
    //       window.location.assign('https://kirovsk.sushigurme.com/home')
    //     }
    //     break;
    //   }
    //   case 'Североморск': {
    //     if (!locationArr.includes('severomorsk')) {
    //       window.location.assign('https://severomorsk.sushigurme.com/home')
    //     }
    //     break;
    //   }
    //   case 'Апатиты': {
    //     if (!locationArr.includes('apatity')) {
    //       window.location.assign('https://apatity.sushigurme.com/home')
    //     }
    //     break;
    //   }
    //   case 'Мончегорск': {
    //     if (!locationArr.includes('monchegorsk')) {
    //       window.location.assign('https://monchegorsk.sushigurme.com/home')
    //     }
    //     break;
    //   }
    //   case 'Ковдор': {
    //     if (!locationArr.includes('kovdor')) {
    //       window.location.assign('https://kovdor.sushigurme.com/home')
    //     }
    //     break;
    //   }
    // }
  }

  private scriptElement: any;

  removeScript() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
    }
  }

  addScriptToHtml() {
    this.scriptElement = this.renderer.createElement('script');
    this.scriptElement.type ='text/javascript'
    this.scriptElement.text = `
      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({id: "3403151", type: "pageView", start: (new Date()).getTime()});
      (function (d, w, id) {
        if (d.getElementById(id)) return;
        var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
        ts.src = "https://top-fwz1.mail.ru/js/code.js";
        var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
        if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
      })(document, window, "tmr-code");
    `;
    this.renderer.appendChild(document.head, this.scriptElement);
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.removeScript();
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.addScriptToHtml();
      }
    });
  }
}
