import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'

import { Observable ,  of } from 'rxjs'

import { catchError } from 'rxjs/operators'

import { Category } from './category.model'
import { HttpErrorHandler, HandleError } from './http-error-handler.service'

const API_URL = environment.apiUrl

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Cache-Control': 'public'
  })
}


@Injectable()
export class CategoryService {
  productsCategoriesUrl = '/products-categories'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ProductsCategoriesService');
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(API_URL + this.productsCategoriesUrl, httpOptions)
      .pipe(
        catchError(this.handleError('getCategories', []))
      )
  }

  getCategory(categoryId: string): Observable<Category[]> {
    return this.http.get<Category[]>(API_URL + this.productsCategoriesUrl + '/' + categoryId, httpOptions)
      .pipe(
        catchError(this.handleError('getCategory', []))
      )
  }
}
