import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { City } from './city.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
}


@Injectable()
export class CityService {
  actionsUrl = '/cities'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('CityService');
  }

  getCities(): Observable<City[]> {
    return this.http.get<City[]>(API_URL + this.actionsUrl, httpOptions)
      .pipe(
        catchError(this.handleError('getCities', []))
      )
  }

  getCityByName(name: string): Observable<City[]> {
    return this.http.post<City[]>(API_URL + this.actionsUrl + '/name', { name: name }, httpOptions)
      .pipe(
        catchError(this.handleError('getCityByName', []))
      )
  }
}
