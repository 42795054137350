// angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms'
import { ServiceWorkerModule } from '@angular/service-worker'
// libs
import { CookieService } from 'ngx-cookie-service';
import { MetrikaModule } from 'ng-yandex-metrika';
// shared
import { SharedModule } from '@shared/shared.module';
// components
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import {httpInterceptorProviders} from './http-interceptors';
import {MessageService} from './models/message.service';
import {RequestCache, RequestCacheWithMap} from './models/request-cache.service';
import {HttpErrorHandler} from './models/http-error-handler.service';

import { environment } from '../environments/environment'

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    HttpClientModule,
    RouterModule,
    AppRoutes,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    FormsModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    MetrikaModule.forRoot(
      { id: 33881464, webvisor: true, clickmap: true,  }, 
 
    ),
  ],
  declarations: [AppComponent],
  providers: [
    CookieService,
    HttpErrorHandler,
    //{ provide: RequestCache, useClass: RequestCacheWithMap },
    MessageService,
    //httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})


/*@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    RouterModule,
    FormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    AppRoutes,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
  ],
  declarations: [AppComponent],
  providers: [
    CookieService,
    HttpErrorHandler,
    MessageService,
    { provide: RequestCache, useClass: RequestCacheWithMap },
    httpInterceptorProviders,
    Globals
  ],
  bootstrap: [AppComponent]
})*/
export class AppModule {
}
