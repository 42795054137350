import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Product } from './product.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Cache-Control': 'public'
  })
}


@Injectable()
export class ProductService {
  productsUrl = '/products'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ProductsService');
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(API_URL + this.productsUrl, httpOptions)
      .pipe(
        catchError(this.handleError('getCategories', []))
      )
  }

  getProduct(productId: string): Observable<Product[]> {
    return this.http.get<Product[]>(API_URL + this.productsUrl + '/' + productId, httpOptions)
      .pipe(
        catchError(this.handleError('getProduct', []))
      )
  }

  getProductsByCategory(categoryId: string): Observable<Product[]> {
    return this.http.get<Product[]>(API_URL + this.productsUrl + '/category/' + categoryId, httpOptions)
      .pipe(
        catchError(this.handleError('getProductsByCategory', []))
      )
  }

  getPopularProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(API_URL + this.productsUrl + '/popular', httpOptions)
      .pipe(
        catchError(this.handleError('getCategories', []))
      )
  }
}
