import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Action } from './action.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const GOOGLE_MAP_API_KEY = environment.googleMapAPIKey

@Injectable()
export class GeolocationService {
  geoLocationUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('GeolocationService');
  }

  getCity(latitude: number, longitude: number, language: string): Observable<any> {
    return this.http.get<any>(this.geoLocationUrl + latitude + ',' + longitude + '&language=' + language + '&key=' + GOOGLE_MAP_API_KEY)
      .pipe(
        catchError(this.handleError('getCity', []))
      )
  }
}
