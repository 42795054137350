import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Page } from './page.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Cache-Control': 'no-cache'
  })
}


@Injectable()
export class PageService {
  pageUrl = '/contents'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('PageService');
  }

  getPages(): Observable<Page[]> {
    return this.http.get<Page[]>(API_URL + this.pageUrl, httpOptions)
      .pipe(
        catchError(this.handleError('getPages', []))
      )
  }

  getPage(pageKey: string): Observable<Page[]> {
    return this.http.get<Page[]>(API_URL + this.pageUrl + '/' + pageKey, httpOptions)
      .pipe(
        catchError(this.handleError('getProduct', []))
      )
  }
}
