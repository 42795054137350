import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Components } from './components.model';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Cache-Control': 'public'
  })
}


@Injectable()
export class ComponentsService {
  componentsUrl = '/components'
  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ProductsService');
  }

  getComponentsByCategory(categoryId: string): Observable<Components[]> {
    return this.http.get<Components[]>(API_URL + this.componentsUrl + '/category/' + categoryId, httpOptions)
      .pipe(
        catchError(this.handleError('getComponentsByCategory', []))
      )
  }
}
