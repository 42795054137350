import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { WrapperComponent } from './wrapper/wrapper.component';
import {AgmCoreModule} from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
      AgmCoreModule.forRoot({
          // apiKey: 'AIzaSyC--FsBSkrjKSjEgtfOimns3uSzjKoUz-c'
          apiKey: 'AIzaSyA-OqAG72OcDLvcMf_AOzPexQXrfEIMTiU'
      })
  ],
  declarations: [
    WrapperComponent
  ]
})
export class LayoutsModule {
}
